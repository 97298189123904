import { reactive, ref, computed, toRaw } from "vue";
import { getDealerApproveRoundNameCN, dealerBreakdownList } from '@/API/payment'

const useBreakdown = () => {
  const column = ref([
    {
      title: "付款轮次",
      dataIndex: "payoutRoundCN",
      width: 100
    },
    // {
    //     title: "区域",
    //     dataIndex: "region",
    //     width: 60
    //   },
      {
        title: "经销商编码",
        dataIndex: "dealerCode",
        width: 100
      },
      {
        title: "经销商名称(英文)",
        dataIndex: "dealerNameEn",
      },
      {
        title: "经销商名称(中文)",
        dataIndex: "dealerNameCn",
      },
      {
        title: "批发来源",
        slots: {
          customRender: 'source'
        },
        width: 335
      },
      {
        title: "操作",
        slots: {
          customRender: 'operation'
        },
        align: 'center',
        width: 100
      },
  ]);
  // 分页
  const pagination = reactive({
    total: 0,
    pageNumber: 1,
    pageSize: 10,
  });
  const payoutRound = ref(null)
  // 表格列表查询
  const res = ref();
  const data = computed(() => {
    return res.value?.content;
  });
  const search = () => {
    const _query = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      payoutRound: payoutRound.value
    }
    // useMock<DataProps>(Data, _query).then((data) => {
    //   res.value = data;
    // });
    dealerBreakdownList(_query).then((data) => {
      pagination.total = data.totalElements
      res.value = data;
    })
  };
  const payoutRoundList = ref()
  // const payoutRoundList = computed(() => {
  //   return payoutRoundRes.value?.payoutRound
  // })
  const getPayoutRoundList = () => {
    return new Promise((resolve, reject) => {
      getDealerApproveRoundNameCN().then((res) => {
        payoutRoundList.value = res
        resolve(payoutRoundList.value)
      })
    })
  }
  const reset = () => {
    payoutRound.value = null
    pagination.pageNumber = 1
    pagination.pageSize = 10
    search()
  }
  // 分页事件
  const pageChange = (val: number) => {
    pagination.pageNumber = val;
    search();
  };
  const sizeChange = (current: number, size: number) => {
    pagination.pageSize = size;
    search();
  };
  return {
    search,
    reset,
    data,
    column,
    pagination,
    pageChange,
    sizeChange,
    payoutRound,
    getPayoutRoundList,
    payoutRoundList
  };
};
export default useBreakdown;
