
import { defineComponent, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ExportOutlined } from "@ant-design/icons-vue";
import TableWrapper from "../components/TableWrapper.vue";
import useBreakdown from "@/hooks/payment/DealerBreakdown/useBreakdown";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const {
      column,
      search,
      reset,
      data,
      pagination,
      pageChange,
      sizeChange,
      getPayoutRoundList,
      payoutRoundList,
      payoutRound,
    } = useBreakdown();
    const init = async () => {
      const list: any  = await getPayoutRoundList()
      payoutRound.value = list[0].payoutRound
      search();
    };
    init();
    const router = useRouter();
    const store = useStore();
    const handleClick = (row: unknown) => {
      store.commit("payment/updateBreakdown", row);
      router.push({
        path: "/DealerBreakdown/Detail",
      });
    };
    return {
      search,
      reset,
      column,
      data,
      payoutRoundList,
      payoutRound,
      handleClick,
      ...toRefs(pagination),
      pageChange,
      sizeChange,
      isPagination: true,
    };
  },
  components: {
    TableWrapper,
    // ExportOutlined,
  },
});
